import { addMinutes } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
	BarChartHorizontal,
	Copy,
	Download,
	Pencil,
	Trash2,
	X,
} from 'lucide-react';
import { Fragment, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import {
	ActionFunctionArgs,
	Link,
	LoaderFunctionArgs,
	isRouteErrorResponse,
	redirect,
	useFetcher,
	useLoaderData,
	useParams,
	useRouteError,
} from 'react-router-dom';
import AuditModal from 'ui/components/AuditModal/AuditModal';
import useAuditModal from 'ui/components/AuditModal/useAuditModal';
import Button from 'ui/components/Button';
import Card from 'ui/components/Card';
import Flex from 'ui/components/Flex/Flex';
import Grid from 'ui/components/Grid';
import KeyValuePairs from 'ui/components/KeyValuePairs';
import List from 'ui/components/List';
import PageHeader from 'ui/components/PageHeader';
import Tabs from 'ui/components/Tabs';
import { ToastType } from 'ui/components/Toaster/Toast';
import { deleteSchema } from 'utils/api/common';
import { formDataAsObject } from 'utils/formData/formData';
import { downloadFile } from 'utils/helpers/file';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { createToast } from 'utils/helpers/toast';
import useRootLoader from 'utils/hooks/useRootLoader';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI, {
	SubscriptionSupportUserItem,
	isDeliveryStatusApplicable,
} from '../../api/SubscriptionAPI';
import DeleteSubscriptionModal from '../../components/DeleteSubscriptionModal';
import { rootLoader } from '../../main';
import { ProductTypeCode } from '../../util/schemas/subscriptionSchema';
import ProductConfigurationCard from './ProductConfigurationCard';

export const loader = async ({ params, request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const subscriptionId = params.subscriptionId as string;

	const subscriptionData = await SubscriptionAPI.getSubscription(
		subscriptionId
	);

	return {
		subscription: subscriptionData,
	};
};

export const action = async ({ params, request }: ActionFunctionArgs) => {
	const formData = await request.formData();
	const action = formData.get('_action') as string;

	const subscriptionId = params.subscriptionId as string;
	if (!subscriptionId) {
		return null;
	}

	if (request.method === 'DELETE' && action === 'delete') {
		const deleteModel = deleteSchema.parse(formDataAsObject(formData));

		const response = await SubscriptionAPI.deleteSubscription(
			subscriptionId,
			deleteModel
		);

		if (response instanceof Error) {
			createToast(ToastType.ERROR, 'Deletion failed');
			return response;
		}

		createToast(ToastType.SUCCESS, 'Subscription deleted successfully');
		return redirect('/subscriptions');
	} else if (request.method === 'POST' && action === 'addSupportUser') {
		const auditComment = formData.get('auditComment') as string;
		await SubscriptionAPI.addSubscriptionSupportUser(subscriptionId, {
			auditComment,
		});
	} else if (request.method === 'DELETE' && action === 'removeSupportUser') {
		const auditComment = formData.get('auditComment') as string;
		await SubscriptionAPI.deleteSubscriptionSupportUser(subscriptionId, {
			auditComment,
		});
	}

	return null;
};

export function supportUsersEnabledFor(productType: ProductTypeCode) {
	return (
		productType === 'web-tool-airline' ||
		productType === 'web-tool-freight-forwarder' ||
		productType === 'web-tool-third-party-operational' ||
		productType === 'web-tool-third-party-revenue' ||
		productType === 'web-tool-iata' ||
		productType === 'web-tool-admin' ||
		productType === 'cdd-contributor-airline' ||
		productType === 'ndd-contributor'
	);
}

type SupportUserListProps = { users: SubscriptionSupportUserItem[] };

const SupportUserList = ({ users }: SupportUserListProps) => {
	const rootLoaderData = useRootLoader() as LoaderData<typeof rootLoader>;
	const profile = rootLoaderData?.profile;

	const fetcher = useFetcher();
	const params = useParams();

	const subscriptionId = params.subscriptionId as string;
	const { confirm: confirmAddUser, ...addUserAuditModalState } =
		useAuditModal();
	const { confirm: confirmRemoveUser, ...removeUserAuditModalState } =
		useAuditModal();

	const optimisticUsers = useMemo(() => {
		let optimisticUsers = [...users];

		const action = fetcher.formData?.get('_action');
		if (!profile) return users;

		switch (action) {
			case 'addSupportUser':
				if (users.some((user) => user.id === profile.id)) return users;

				return [
					...users,
					{
						id: profile.id,
						name: profile.name,
						createdOn: addMinutes(new Date(), new Date().getTimezoneOffset()),
					},
				];
			case 'removeSupportUser':
				return optimisticUsers.filter((user) => user.id !== profile?.id);
			default:
				return users;
		}
	}, [fetcher.formData, profile, users]);

	const isSupportUser = optimisticUsers.some((user) => user.id === profile?.id);

	const addSupportUser = async () => {
		const comment = await confirmAddUser();

		if (!comment) {
			return;
		}

		await fetcher.submit(
			{
				_action: 'addSupportUser',
				auditComment: comment,
			},
			{
				method: 'post',
				action: `/subscriptions/${subscriptionId}`,
			}
		);
	};

	const removeSupportUser = async () => {
		const comment = await confirmRemoveUser();

		if (!comment) {
			return;
		}

		await fetcher.submit(
			{
				_action: 'removeSupportUser',
				auditComment: comment,
			},
			{
				method: 'delete',
				action: `/subscriptions/${subscriptionId}`,
			}
		);
	};

	return (
		<div>
			<List>
				{optimisticUsers.length === 0 && <List.Item label="No support users" />}

				{optimisticUsers.map((user) => {
					const isSelf = user.id === profile?.id;

					return (
						<List.Item
							key={user.id}
							label={user.name}
							description={`Added on ${formatInTimeZone(
								user.createdOn,
								'UTC',
								"MMM do 'at' HH:mm"
							)}`}
							actions={
								isSelf
									? [
											{
												icon: X,
												altText: 'Remove',
												onClick: removeSupportUser,
											},
									  ]
									: undefined
							}
						/>
					);
				})}

				{!isSupportUser && profile && (
					<List.Item
						label="Add yourself as a support user"
						onClick={addSupportUser}
					/>
				)}
			</List>

			<AuditModal {...addUserAuditModalState} title="Add Support User" />
			<AuditModal {...removeUserAuditModalState} title="Remove Support User" />
		</div>
	);
};

export function SubscriptionDetails() {
	const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
	const data = useLoaderData() as LoaderData<typeof loader>;

	const handleDownload = async (subscriptionId: string) => {
		toast.loading('Exporting...');

		const response = await SubscriptionAPI.getSubscriptionDownloadData(
			subscriptionId
		);

		downloadFile(response.url, response.fileName);
	};

	const subscriptionTabs = [
		{
			name: 'Users',
			to: '',
		},
	];

	const productsForCddSubmissionsTab = ['cdd-contributor-airline'];

	const productsForNddSubmissionsTab = ['ndd-contributor'];

	const productsForWebToolReportsTab = [
		'web-tool-airline',
		'web-tool-freight-forwarder',
		'web-tool-third-party-operational',
		'web-tool-third-party-revenue',
		'web-tool-iata',
		'web-tool-admin',
	];
	const excludedProductsForGenerateReportTab = [
		...productsForWebToolReportsTab,
		'cdd-contributor-airline',
		'ndd-contributor',
	];
	const excludedProductsForDeliverablesTab = [
		...productsForWebToolReportsTab,
		'cdd-contributor-airline',
		'ndd-contributor',
	];

	if (
		!excludedProductsForDeliverablesTab.includes(
			data.subscription.productConfiguration.type
		)
	) {
		subscriptionTabs.push({
			name: 'Deliverables',
			to: `/reports`,
		});
	}

	if (
		productsForCddSubmissionsTab.includes(
			data.subscription.productConfiguration.type
		)
	) {
		subscriptionTabs.push({
			name: 'CDD Submissions',
			to: `/cdd-submissions`,
		});
	}

	if (
		productsForNddSubmissionsTab.includes(
			data.subscription.productConfiguration.type
		)
	) {
		subscriptionTabs.push({
			name: 'NDD Submissions',
			to: `/ndd-submissions`,
		});
	}

	if (
		!excludedProductsForGenerateReportTab.includes(
			data.subscription.productConfiguration.type
		) &&
		data.subscription.deliveryStatus.value == 'enabled'
	) {
		subscriptionTabs.push({
			name: 'Generate Report',
			to: `/reports/generate`,
		});
	}

	if (
		productsForWebToolReportsTab.includes(
			data.subscription.productConfiguration.type
		)
	) {
		subscriptionTabs.push({
			name: 'Reports',
			to: `/web-tool-reports`,
		});
	}

	const headerFields = [
		{
			key: 'Customer',
			value: (
				<Link to={`/customers/${data.subscription.customer.id}`}>
					{data.subscription.customer.name} (
					{data.subscription.customer.customerNumber})
				</Link>
			),
		},
		{ key: 'Product', value: data.subscription.product.name },
		{
			key: 'Start',
			value: `${data.subscription.startPeriod.month
				.toString()
				.padStart(2, '0')}/${data.subscription.startPeriod.year}`,
		},
		{
			key: 'End',
			value: `${data.subscription.endPeriod.month
				.toString()
				.padStart(2, '0')}/${data.subscription.endPeriod.year}`,
		},
		{
			key: 'Licenses',
			value: data.subscription.numberOfLicenses ?? '-',
		},
	];

	if (isDeliveryStatusApplicable(data.subscription.product)) {
		headerFields.splice(4, 0, {
			key: 'Delivery Status',
			value: data.subscription.deliveryStatus.label,
		});
	}

	return (
		<Fragment>
			<div className="content">
				<PageHeader
					title={`${
						data.subscription.name || data.subscription.product.name
					} (${data.subscription.subscriptionNumber})`}
				>
					<Button
						to={`/admin-audit-log/?subscriptionIds=${data.subscription.id}&dateRange=0`}
						variant="secondary"
						icon={BarChartHorizontal}
					>
						Audit Log
					</Button>
					<Button
						icon={Trash2}
						variant="secondary"
						onClick={() => setDeleteModalOpen(true)}
					>
						Delete
					</Button>
					<Button
						to={`/subscriptions/create?templateId=${data.subscription.id}`}
						variant="secondary"
						icon={Copy}
					>
						Duplicate
					</Button>
					<Button
						to={`/subscriptions/${data.subscription.id}/edit`}
						variant="secondary"
						icon={Pencil}
					>
						Edit
					</Button>
				</PageHeader>

				<KeyValuePairs entries={headerFields} />

				<Grid columns="2fr 1fr">
					<Tabs
						baseUrl={`/subscriptions/${data.subscription.id}`}
						tabs={subscriptionTabs}
					/>

					<Flex direction="column" gap={20}>
						{supportUsersEnabledFor(data.subscription.product.productType) && (
							<Card label="IATA Support Users">
								<SupportUserList users={data.subscription.iataSupportUsers} />
							</Card>
						)}

						<ProductConfigurationCard
							config={data.subscription.productConfiguration}
						/>

						<Button
							icon={Download}
							onClick={() => {
								handleDownload(data.subscription.id).finally(() => {
									toast.remove();
								});
							}}
						>
							Download data
						</Button>
					</Flex>
				</Grid>

				<DeleteSubscriptionModal
					subscription={data.subscription}
					isOpen={isDeleteModalOpen}
					onClose={() => setDeleteModalOpen(false)}
				/>
			</div>
		</Fragment>
	);
}

export function ReportErrorBoundary() {
	const error = useRouteError();

	// re-throw to let the RootErrorBoundary handle it if it's a route error
	if (!isRouteErrorResponse(error)) {
		throw error;
	}

	return (
		<>
			<h1>Subscription could not be found</h1>
			<p>
				Go back to the <Link to="./">list of subscriptions</Link>
			</p>
		</>
	);
}

export const SUBSCRIPTION_DETAILS_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	action: action,
	element: <SubscriptionDetails />,
	errorElement: <ReportErrorBoundary />,
	handle: {
		breadcrumbs: ({ data }: { data: LoaderData<typeof loader> }) => {
			return [
				{
					label: 'Customer Subscriptions',
					path: `/customers/${data.subscription.customer.id}/subscriptions`,
				},
				{
					label: `${
						data.subscription.name || data.subscription.product.name
					} (${data.subscription.subscriptionNumber})`,
				},
			];
		},
	},
};
