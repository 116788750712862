import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';
import { LabeledValue, Paginated } from 'utils/types/common';
import { CustomerOption } from './CustomerAPI';
import { UserOption } from './UserAPI';

export type TimeSpan = `${number}:${number}:${number}.${number}`;

type WebToolReportParameter = {
	name: string;
	value: string[];
};

export type UsageTrackingDataRow = {
	id: string;
	executedAt: Date;
	reportName: string;
	user: string;
	customer: string;
	status: LabeledValue;
	failureMessage: string | undefined;
	runType: string;
	outputFormat: string;
	exportedFormat: string | null;
	formatChangeMessage: string | undefined;
	queryTime?: TimeSpan;
	runTime?: TimeSpan;
	rowCount: number;
	parameters: WebToolReportParameter[];
	columns: string[];
};

export type GetUsageTrackingDataResponse = {
	trackingData: Paginated<UsageTrackingDataRow>;
	customersSelected: CustomerOption[];
	usersSelected: UserOption[];
	runAfterDate: Date;
	runBeforeDate: Date;
};

class WebToolUsageTrackingAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static getUsageTrackingData(request: URLSearchParams) {
		return WebToolUsageTrackingAPI.get<GetUsageTrackingDataResponse>(
			`/web-tool/usage-tracking?${request}`
		);
	}

	static beginUsageTrackingDataExport(
		format: string,
		request: URLSearchParams
	) {
		return WebToolUsageTrackingAPI.post<GetUsageTrackingDataResponse>(
			`/web-tool/usage-tracking/export?exportType=${format}&${request}`
		);
	}
}

export default WebToolUsageTrackingAPI;
