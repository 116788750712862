import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	createBrowserRouter,
	createRoutesFromChildren,
	LoaderFunctionArgs,
	redirect,
	Route,
	RouterProvider,
} from 'react-router-dom';
import ErrorBoundary from 'ui/components/ErrorBoundary';
import PageLoader from 'ui/components/PageLoader';
import AdminUserAPI from './api/AdminUserAPI';
import App from './App';
import { msalInstance } from './auth/authConfig';

import { CREATE_ACCOUNT_MANAGER_ROUTE } from './routes/account-managers/account-manager-create';
import { ACCOUNT_MANAGER_DETAILS_ROUTE } from './routes/account-managers/account-manager-details';
import { ACCOUNT_MANAGER_DETAILS_CUSTOMERS_ROUTE } from './routes/account-managers/account-manager-details-customers';
import { EDIT_ACCOUNT_MANAGER_ROUTE } from './routes/account-managers/account-manager-edit';
import { LIST_ACCOUNT_MANAGERS_ROUTE } from './routes/account-managers/account-manager-list';
import { ADHOC_REPORTS_CREATE_ROUTE } from './routes/adhoc-reports/adhoc-reports-create';
import { ADHOC_REPORTS_EDIT_ROUTE } from './routes/adhoc-reports/adhoc-reports-edit';
import { CREATE_ADMIN_USER_ROUTE } from './routes/admin-users/admin-user-create';
import { ADMIN_USER_DETAILS_ROUTE } from './routes/admin-users/admin-user-details';
import { EDIT_ADMIN_USER_ROUTE } from './routes/admin-users/admin-user-edit';
import { LIST_ADMIN_USERS_ROUTE } from './routes/admin-users/admin-user-list';
import { AIR_IMPACT_REPORTS_ROUTES as air_impact_reports } from './routes/air-impact-reports';
import { LOGGED_IN_ROUTE } from './routes/auth/logged-in';
import { LOGGED_OUT_ROUTE } from './routes/auth/logged-out';
import { LOGIN_ROUTE } from './routes/auth/login';
import { LOGOUT_ROUTE } from './routes/auth/logout';
import { CUSTOMER_TEAMS_CREATE_ROUTE } from './routes/customer-teams/customer-teams-create';
import { CUSTOMER_TEAMS_EDIT_ROUTE } from './routes/customer-teams/customer-teams-edit';
import { CREATE_CUSTOMER_ROUTE } from './routes/customers/customer-create';
import { CUSTOMER_DETAILS_ROUTE } from './routes/customers/customer-details';
import { CUSTOMER_DETAILS_REPORTS_ROUTE } from './routes/customers/customer-details-reports';
import { CUSTOMER_DETAILS_SUBSCRIPTIONS_ROUTE } from './routes/customers/customer-details-subscriptions';
import { CUSTOMER_DETAILS_TEAMS_ROUTE } from './routes/customers/customer-details-teams';
import { CUSTOMER_DETAILS_USERS_ROUTE } from './routes/customers/customer-details-users';
import { EDIT_CUSTOMER_ROUTE } from './routes/customers/customer-edit';
import { LIST_CUSTOMERS_ROUTE } from './routes/customers/customers-list';
import { HOME_ROUTE } from './routes/home/home';
import { MonitorArea } from './routes/monitor/monitor';
import {
	MonitorIndex,
	loader as monitorIndexLoader,
} from './routes/monitor/monitor.index';
import { POST_PUBLISH_DASHBOARD_ROUTE as postPublishDashboard } from './routes/production/post-publish';
import { SUMMARY_DASHBOARD_ROUTE as publishSummaryDashboard } from './routes/production/summary-dashboard';
import { ALL_SUBSCRIPTIONS_ROUTE } from './routes/reports/reports.all-subscriptions';
import { SUBSCRIPTIONS_MONTHLY_REPORTS_ROUTE } from './routes/reports/reports.monthly-reports';
import { SUBSCRIPTIONS_WEEKLY_REPORTS_ROUTE } from './routes/reports/reports.weekly-reports';
import { REPORT_DOWNLOAD_ROUTE } from './routes/subscriptions/report-download';
import { CREATE_SUBSCRIPTION_ROUTE } from './routes/subscriptions/subscription-create';
import { SUBSCRIPTION_DETAILS_ROUTE } from './routes/subscriptions/subscription-details';
import { SUBSCRIPTION_DETAILS_REPORTS_ROUTE } from './routes/subscriptions/subscription-details-reports';
import { SUBSCRIPTION_DETAILS_USERS_ROUTE } from './routes/subscriptions/subscription-details-users';
import { EDIT_SUBSCRIPTION_ROUTE } from './routes/subscriptions/subscription-edit';
import { SUBSCRIPTION_GENERATE_REPORT_ROUTE } from './routes/subscriptions/subscription-generate-report';
import { LIST_SUBSCRIPTIONS_ROUTE } from './routes/subscriptions/subscriptions-list';
import { ADHOC_REPORT_DOWNLOAD_ROUTE } from './routes/users/report-download';
import { CREATE_USER_ROUTE } from './routes/users/user-create';
import { USER_DETAILS_ROUTE } from './routes/users/user-details';
import { USER_DETAILS_REPORTS_ROUTE } from './routes/users/user-details-reports';
import { USER_DETAILS_SUBSCRIPTIONS_ROUTE } from './routes/users/user-details-subscriptions';
import { USER_DETAILS_TEAMS_ROUTE } from './routes/users/user-details-teams';
import { EDIT_USER_ROUTE } from './routes/users/user-edit';
import { LIST_USERS_ROUTE } from './routes/users/user-list';
import { CREATE_WEB_ANNOUNCEMENT_ROUTE } from './routes/web-announcements/web-announcements-create';
import { DETAILS_WEB_ANNOUNCEMENT_ROUTE } from './routes/web-announcements/web-announcements-details';
import { EDIT_WEB_ANNOUNCEMENT_ROUTE } from './routes/web-announcements/web-announcements-edit';
import { LIST_WEB_ANNOUNCEMENTS_LIST } from './routes/web-announcements/web-announcements-list';

import { ENVIRONMENT_ADMIN } from 'environment';
import {
	handlePendingLogout,
	isInIframe,
	mockMsalAccount,
	performMsalAuth,
} from 'utils/helpers/msal';
import { isTestRunner } from 'utils/helpers/test';
import { ADMIN_AUDIT_LOG_ROUTES } from './routes/admin-audit-log';
import { ADMIN_EXPORTS_ROUTES } from './routes/admin-exports';
import { ADMIN_USER_DETAILS_NOTIFICATIONS_ROUTE } from './routes/admin-users/admin-user-details-notifications';
import { CDD_CONTRIBUTOR_ROUTES } from './routes/cdd-contributor';
import { EMAIL_TEMPLATES_ROUTE } from './routes/email-templates';
import { FREIGHT_FORWARDERS_ROUTES } from './routes/freight-forwarders';
import { AIRCRAFT_CAPACITY_ROUTES } from './routes/aircraft-capacity';
import { CDD_MONITOR_ROUTE } from './routes/monitor/cdd-monitor';
import { HOTFILE_MONITOR_ROUTE } from './routes/monitor/hotfile-monitor';
import { SYSTEM_STATUS_ROUTE } from './routes/monitor/system-status';
import { REPORT_NOTIFICATIONS_ROUTES } from './routes/report-notifications';
import { REPORT_DISTRIBUTION_ROUTES } from './routes/static-report-distribution';
import { SUBSCRIPTION_DETAILS_CDD_ROUTE } from './routes/subscriptions/subscription-details-cdd';
import { SUBSCRIPTION_DETAILS_NDD_ROUTE } from './routes/subscriptions/subscription-details-ndd';
import { SUBSCRIPTION_DETAILS_WEB_TOOL_REPORTS_ROUTE } from './routes/subscriptions/subscription-details-web-tool-reports';
import { NOTIFICATIONS_ROUTES } from './routes/system-notifications';
import { USAGE_TRACKING_ROUTES } from './routes/usage-tracking';
import { WEB_TOOL_REPORTS_ROUTES } from './routes/web-tool-reports';
import { WEB_TOOL_SCHEDULES_ROUTES } from './routes/web-tool-schedules';
import { WEB_TOOL_ADMIN_DOWNLOAD_ROUTE } from './routes/web-tool-schedules/web-tool-download';
import { WEB_TOOL_USAGE_TRACKING_ROUTES } from './routes/web-tool-usage-tracking';

export const rootLoader = async ({}: LoaderFunctionArgs) => {
	if (msalInstance.getActiveAccount() === null) {
		return null;
	}

	const profile = await AdminUserAPI.getAdminProfile().catch(() => null);
	return profile ? { profile } : null;
};

// Make MSAL available
if (!window.msal) {
	window.msal = {
		instance: msalInstance,
		scope: ENVIRONMENT_ADMIN.msal.scope,
	};
}

const makeRouter = () =>
	createBrowserRouter(
		createRoutesFromChildren(
			<Route loader={rootLoader} errorElement={<ErrorBoundary isRoot />}>
				<Route path="logged-in" {...LOGGED_IN_ROUTE} />
				<Route path="logout" {...LOGOUT_ROUTE} />
				<Route path="logged-out" {...LOGGED_OUT_ROUTE} />

				<Route
					path="/subscriptions/reports/download/:reportId"
					{...REPORT_DOWNLOAD_ROUTE}
				/>
				<Route
					path="/adhoc-reports/download/:reportId"
					{...ADHOC_REPORT_DOWNLOAD_ROUTE}
				/>

				<Route
					path="/"
					element={<App />}
					handle={{ breadcrumbs: { label: 'Home' } }}
				>
					<Route errorElement={<ErrorBoundary />}>
						<Route index {...HOME_ROUTE} />
						<Route path="login" {...LOGIN_ROUTE} />
						<Route
							path="customers"
							handle={{ breadcrumbs: { label: 'Customers' } }}
						>
							<Route index {...LIST_CUSTOMERS_ROUTE} />
							<Route path="create" {...CREATE_CUSTOMER_ROUTE} />
							<Route path=":customerId" {...CUSTOMER_DETAILS_ROUTE}>
								<Route index {...CUSTOMER_DETAILS_USERS_ROUTE} />
								<Route
									path="subscriptions"
									{...CUSTOMER_DETAILS_SUBSCRIPTIONS_ROUTE}
								/>
								<Route path="reports" {...CUSTOMER_DETAILS_REPORTS_ROUTE} />
								<Route path="teams" {...CUSTOMER_DETAILS_TEAMS_ROUTE} />
							</Route>
							<Route path=":customerId/edit" {...EDIT_CUSTOMER_ROUTE} />
						</Route>

						<Route path="customer-teams">
							<Route path="create" {...CUSTOMER_TEAMS_CREATE_ROUTE} />
							<Route path=":teamId/edit" {...CUSTOMER_TEAMS_EDIT_ROUTE} />
						</Route>

						<Route path="adhoc-reports">
							<Route path="create" {...ADHOC_REPORTS_CREATE_ROUTE} />
							<Route path=":reportId/edit" {...ADHOC_REPORTS_EDIT_ROUTE} />
						</Route>

						<Route
							path="subscriptions"
							handle={{ breadcrumbs: { label: 'Subscriptions' } }}
						>
							<Route index {...LIST_SUBSCRIPTIONS_ROUTE} />
							<Route path=":subscriptionId" {...SUBSCRIPTION_DETAILS_ROUTE}>
								<Route index {...SUBSCRIPTION_DETAILS_USERS_ROUTE} />
								<Route
									path="cdd-submissions"
									{...SUBSCRIPTION_DETAILS_CDD_ROUTE}
								/>
								<Route
									path="ndd-submissions"
									{...SUBSCRIPTION_DETAILS_NDD_ROUTE}
								/>
								<Route path="reports" {...SUBSCRIPTION_DETAILS_REPORTS_ROUTE} />
								<Route
									path="web-tool-reports"
									{...SUBSCRIPTION_DETAILS_WEB_TOOL_REPORTS_ROUTE}
								/>
								<Route
									path="reports/generate"
									{...SUBSCRIPTION_GENERATE_REPORT_ROUTE}
								/>
							</Route>
							<Route path="create" {...CREATE_SUBSCRIPTION_ROUTE} />
							<Route path=":subscriptionId/edit" {...EDIT_SUBSCRIPTION_ROUTE} />
						</Route>

						<Route path="users" handle={{ breadcrumbs: { label: 'Users' } }}>
							<Route index {...LIST_USERS_ROUTE} />
							<Route path="create" {...CREATE_USER_ROUTE} />
							<Route path=":userId" {...USER_DETAILS_ROUTE}>
								<Route index {...USER_DETAILS_SUBSCRIPTIONS_ROUTE} />
								<Route path="reports" {...USER_DETAILS_REPORTS_ROUTE} />
								<Route path="teams" {...USER_DETAILS_TEAMS_ROUTE} />
							</Route>
							<Route path=":userId/edit" {...EDIT_USER_ROUTE} />
						</Route>
						<Route
							path="admin-users"
							handle={{ breadcrumbs: { label: 'Admin Users' } }}
						>
							<Route index {...LIST_ADMIN_USERS_ROUTE} />
							<Route path="create" {...CREATE_ADMIN_USER_ROUTE} />
							<Route path=":userId" {...ADMIN_USER_DETAILS_ROUTE}>
								<Route index {...ADMIN_USER_DETAILS_NOTIFICATIONS_ROUTE} />
							</Route>
							<Route path=":userId/edit" {...EDIT_ADMIN_USER_ROUTE} />
						</Route>
						<Route
							path="account-managers"
							handle={{ breadcrumbs: { label: 'Account Managers' } }}
						>
							<Route index {...LIST_ACCOUNT_MANAGERS_ROUTE} />
							<Route path="create" {...CREATE_ACCOUNT_MANAGER_ROUTE} />
							<Route
								path=":accountManagerId"
								{...ACCOUNT_MANAGER_DETAILS_ROUTE}
							>
								<Route index {...ACCOUNT_MANAGER_DETAILS_CUSTOMERS_ROUTE} />
							</Route>
							<Route
								path=":accountManagerId/edit"
								{...EDIT_ACCOUNT_MANAGER_ROUTE}
							/>
						</Route>

						<Route path="web-announcements" {...LIST_WEB_ANNOUNCEMENTS_LIST}>
							<Route path=":id" {...DETAILS_WEB_ANNOUNCEMENT_ROUTE} />
						</Route>

						<Route
							path="web-announcements"
							handle={{ breadcrumbs: { label: 'Web Announcements' } }}
						>
							<Route path="create" {...CREATE_WEB_ANNOUNCEMENT_ROUTE} />
							<Route path=":id/edit" {...EDIT_WEB_ANNOUNCEMENT_ROUTE} />
						</Route>

						<Route path="air-impact-reports" {...air_impact_reports.list}>
							<Route path=":id" {...air_impact_reports.details} />
						</Route>

						<Route
							path="air-impact-reports"
							handle={{ crumb: () => 'Air Cargo Impact Reports' }}
						>
							<Route path="create" {...air_impact_reports.create} />
							<Route path=":id/edit" {...air_impact_reports.edit} />
						</Route>

						<Route path="freight-forwarders">
							<Route index {...FREIGHT_FORWARDERS_ROUTES.list} />
							<Route path="upload" {...FREIGHT_FORWARDERS_ROUTES.upload} />
						</Route>

						<Route path="aircraft-capacity">
							<Route index {...AIRCRAFT_CAPACITY_ROUTES.list} />
							<Route path="upload" {...AIRCRAFT_CAPACITY_ROUTES.upload} />
						</Route>

						<Route path="admin-exports">
							<Route index {...ADMIN_EXPORTS_ROUTES.list} />
						</Route>

						<Route
							path="email-templates"
							handle={{ breadcrumbs: { label: 'Email Templates' } }}
						>
							<Route index {...EMAIL_TEMPLATES_ROUTE.list} />
							<Route path=":emailId" {...EMAIL_TEMPLATES_ROUTE.edit} />
						</Route>

						<Route path="monitoring" element={<MonitorArea />}>
							<Route
								index
								loader={monitorIndexLoader}
								element={<MonitorIndex />}
							/>
							<Route path="hotfile-monitor" {...HOTFILE_MONITOR_ROUTE} />
							<Route path="cdd-monitor" {...CDD_MONITOR_ROUTE} />
							<Route path="system-status" {...SYSTEM_STATUS_ROUTE} />
						</Route>
						<Route path="reports">
							<Route index loader={() => redirect('/reports/monitor')} />
							<Route path="monitor" {...ALL_SUBSCRIPTIONS_ROUTE} />
							<Route
								path="monthly-reports"
								{...SUBSCRIPTIONS_MONTHLY_REPORTS_ROUTE}
							/>
							<Route
								path="weekly-reports"
								{...SUBSCRIPTIONS_WEEKLY_REPORTS_ROUTE}
							/>
						</Route>
						<Route
							path="static-report-distribution"
							handle={{ breadcrumbs: { label: 'iiNet Scheduler' } }}
						>
							<Route index {...REPORT_DISTRIBUTION_ROUTES.list} />
							<Route path=":id" {...REPORT_DISTRIBUTION_ROUTES.details} />
						</Route>
						<Route
							path="report-notifications"
							handle={{ breadcrumbs: { label: 'Report Notifications' } }}
						>
							<Route index {...REPORT_NOTIFICATIONS_ROUTES.list}></Route>
							<Route path=":id" {...REPORT_NOTIFICATIONS_ROUTES.details} />
						</Route>
						<Route path="publish/summary" {...publishSummaryDashboard} />
						<Route path="publish/after" {...postPublishDashboard} />
						<Route
							path="admin-audit-log"
							handle={{ breadcrumbs: { label: 'Admin Audit Log' } }}
						>
							<Route index {...ADMIN_AUDIT_LOG_ROUTES.list}></Route>
						</Route>
						<Route
							path="usage-tracking"
							handle={{ breadcrumbs: { label: 'Usage Tracking' } }}
						>
							<Route index {...USAGE_TRACKING_ROUTES.list}></Route>
						</Route>
						<Route
							path="web-tool-usage-tracking"
							handle={{ breadcrumbs: { label: 'Web Tool Usage Tracking' } }}
						>
							<Route index {...WEB_TOOL_USAGE_TRACKING_ROUTES.list}></Route>
						</Route>
						<Route
							path="web-tool-reports"
							handle={{ breadcrumbs: { label: 'Web Tool Reports' } }}
						>
							<Route index {...WEB_TOOL_REPORTS_ROUTES.list}></Route>
						</Route>
					</Route>
					<Route
						path="web-tool-schedules"
						handle={{ breadcrumbs: { label: 'Web Tool Schedules' } }}
					>
						<Route index {...WEB_TOOL_SCHEDULES_ROUTES.list}></Route>
					</Route>
					<Route
						path="/web-tool-schedules/:reportId/download"
						{...WEB_TOOL_ADMIN_DOWNLOAD_ROUTE}
					/>
					<Route path="cdd-contributor">
						<Route index {...CDD_CONTRIBUTOR_ROUTES.list} />
						<Route path=":id" {...CDD_CONTRIBUTOR_ROUTES.download} />
					</Route>
					<Route path="system-notifications">
						<Route path="create" {...NOTIFICATIONS_ROUTES.create} />
						<Route path=":id" {...NOTIFICATIONS_ROUTES.edit} />
					</Route>
				</Route>
			</Route>
		)
	);

const mount = async () => {
	if (window.location.pathname === '/logged-in' && isInIframe()) {
		document.body.innerHTML = '';
		return;
	}

	const pageLoaderStatus = document.getElementById('pageLoaderStatus');
	if (pageLoaderStatus) {
		pageLoaderStatus.textContent = 'Authenticating...';
	}

	await msalInstance.initialize();
	const redirectResult = await msalInstance.handleRedirectPromise();
	window.msal.redirectResult = redirectResult ?? undefined;

	if (!isTestRunner) {
		const redirectResult = await msalInstance.handleRedirectPromise();
		window.msal.redirectResult = redirectResult ?? undefined;

		await performMsalAuth(msalInstance, ENVIRONMENT_ADMIN.msal.scope).catch(
			() => null
		);

		window.addEventListener('focus', handlePendingLogout);
	} else {
		mockMsalAccount(msalInstance);
	}

	const rootElement = document.getElementById('root');
	if (!rootElement) {
		throw new Error('Root element not found');
	}

	const router = makeRouter();
	window.router = router;
	const reactRoot = ReactDOM.createRoot(rootElement);

	reactRoot.render(
		<React.StrictMode>
			<RouterProvider
				router={router}
				fallbackElement={<PageLoader label="Initializing CargoIS Admin..." />}
			/>
		</React.StrictMode>
	);
};

mount();
