import { Download, Upload } from 'lucide-react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import AircraftCapacityAPI from '../../api/AircraftCapacityApi';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import * as exportAdminColumns from '../../util/columns/adminExportsColumns';
import AdminExportsAPI from '../../api/AdminExportsAPI';
import { useExportDownload } from '../../hooks/useExportDownload';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	const urlParams = new URL(request.url).searchParams;
	urlParams.append('exportType', 'aircraft-capacity');
	return await AdminExportsAPI.getAdminExports(urlParams);
};

const COLUMNS = [
	exportAdminColumns.statusColumn,
	exportAdminColumns.createdAtColumn,
	exportAdminColumns.startedAtColumn,
	exportAdminColumns.completedAtColumn,
	exportAdminColumns.exportFileNameColumn,
	exportAdminColumns.titleColumn,
];

export function AircraftCapacityIndexPage() {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [downloadInProgress, downloadExport] = useExportDownload();

	const exportClick = async () => {
		const result = await AircraftCapacityAPI.createAndRunExport();

		if (!(result instanceof Error)) {
			downloadExport(result.id, true);
		}
	};

	return (
		<div className="content">
			<PageHeader title="Aircraft Capacity">
				<RevalidationButton>Refresh</RevalidationButton>
				<Button
					variant="secondary"
					icon={Download}
					onClick={exportClick}
					disabled={downloadInProgress}
				>
					Export
				</Button>
				<Button variant="secondary" icon={Upload} to="upload">
					Upload
				</Button>
			</PageHeader>

			<Table identifierKey="id" columns={COLUMNS} data={data.exports.items} />

			<Pagination
				baseUrl={new URL(window.location.href)}
				page={data.exports.page}
				pageParameterName="page"
				pageSize={data.exports.pageSize}
				itemCount={data.exports.totalCount}
			/>
		</div>
	);
}

export const MAIN_AIRCRAFT_CAPACITY_ROUTE: DecentralizedRouteProps = {
	loader: loader,
	element: <AircraftCapacityIndexPage />,
};
