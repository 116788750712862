import { Download, RefreshCcw, Search } from 'lucide-react';
import { useId } from 'react';
import { LoaderFunctionArgs, useLoaderData } from 'react-router-dom';
import Button from 'ui/components/Button';
import MultiSelectField from 'ui/components/MultiSelectField';
import PageHeader from 'ui/components/PageHeader';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Sidebar from 'ui/components/Sidebar';
import Table from 'ui/components/Table';
import ValidatedForm from 'ui/components/ValidatedForm';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { DecentralizedRouteProps } from 'utils/types/common';
import { LoaderData } from 'utils/types/loaderData';
import { DropdownField } from '../../forms/DropdownField';
import * as adminAuditLogColumns from '../../util/columns/adminAuditLogColumns';
import AdminAuditLogApi from '../../api/AdminAuditLogApi';
import AdminUserPicker from '../../components/AdminUserPicker';
import CustomerPicker from '../../components/CustomerPicker';
import SubscriptionPicker from '../../components/SubscriptionPicker';
import UserPicker from '../../components/UserPicker';
import { useExportDownload } from '../../hooks/useExportDownload';

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAuthentication(request);

	const response = await AdminAuditLogApi.getAdminAuditEvents(
		new URL(request.url).searchParams
	);

	return {
		dateRangeSelected: response.dateRangeSelected,
		adminUsersSelected: response.adminUsersSelected,
		customersSelected: response.customersSelected,
		subscriptionsSelected: response.subscriptionsSelected,
		usersSelected: response.usersSelected,
		actionsSelected: response.actionsSelected,
		entitiesSelected: response.entitiesSelected,
		rolesSelected: response.rolesSelected,
		dateRangeOptions: response.dateRangeOptions,
		entityOptions: response.entityOptions,
		actionOptions: response.actionOptions,
		roleOptions: response.roleOptions,
		events: response.auditEvents,
	};
};

export function AdminAuditLogListPage() {
	const formId = useId();
	const data = useLoaderData() as LoaderData<typeof loader>;
	const [downloadInProgress, downloadExport] = useExportDownload();

	const columns = [
		adminAuditLogColumns.timeStampColumn,
		adminAuditLogColumns.adminUserColumn,
		adminAuditLogColumns.roleColumn,
		adminAuditLogColumns.actionColumn,
		adminAuditLogColumns.entityColumn,
		adminAuditLogColumns.detailColumn,
		adminAuditLogColumns.adminCommentColumn,
	];

	const downloadClick = async () => {
		const request = new Request(window.location.href);

		const url = new URL(request.url);
		const searchParams = url.searchParams;

		const result = await AdminAuditLogApi.beginAdminAuditEventsDataExport(
			searchParams
		);

		if (!(result instanceof Error)) {
			downloadExport(result.id);
		}
	};

	return (
		<Sidebar.Wrapper>
			<div className="content">
				<PageHeader title="Admin Audit Log">
					<RevalidationButton>Refresh</RevalidationButton>
					<Button
						onClick={downloadClick}
						disabled={downloadInProgress}
						icon={Download}
					>
						Download XLSX
					</Button>
				</PageHeader>
				<Table identifierKey="id" columns={columns} data={data.events.items} />
				<Pagination
					baseUrl={new URL(window.location.href)}
					page={data.events.page}
					pageParameterName="page"
					pageSize={data.events.pageSize}
					itemCount={data.events.totalCount}
				/>
			</div>
			<Sidebar title="Admin Audit Filter">
				<ValidatedForm method="get" id={formId}>
					<DropdownField
						label="Date Range"
						name="dateRange"
						placeholder="Past Day"
						options={data.dateRangeOptions}
						identifierKey="value"
						contentSource={(data) => data.label}
						initialValue={data.dateRangeSelected}
						isClearable={false}
					/>

					<AdminUserPicker
						name="adminUserIds"
						label="Admin User"
						initialValues={
							data.adminUsersSelected ? data.adminUsersSelected : []
						}
						isMulti={true}
					/>

					<MultiSelectField
						label="User Type"
						name="roles"
						identifierKey="value"
						placeholder="Please select..."
						contentSource={(opt) => `${opt.label}`}
						options={data.roleOptions}
						pillName={(item) => item.label}
						initialValues={data.rolesSelected}
					/>

					<MultiSelectField
						label="Action"
						name="actions"
						identifierKey="value"
						placeholder="Please select..."
						contentSource={(opt) => `${opt.label}`}
						options={data.actionOptions}
						pillName={(item) => item.label}
						initialValues={data.actionsSelected}
					/>

					<MultiSelectField
						label="Entity"
						name="entities"
						identifierKey="value"
						placeholder="Please select..."
						contentSource={(opt) => `${opt.label}`}
						options={data.entityOptions}
						pillName={(item) => item.label}
						initialValues={data.entitiesSelected}
					/>

					<CustomerPicker
						name="customerIds"
						label="Customer"
						initialValues={data.customersSelected ? data.customersSelected : []}
						isMulti={true}
					/>

					<SubscriptionPicker
						name="subscriptionIds"
						label="Subscription"
						initialValues={
							data.subscriptionsSelected ? data.subscriptionsSelected : []
						}
						isMulti={true}
					/>

					<UserPicker
						name="userIds"
						label="User"
						initialValues={data.usersSelected ? data.usersSelected : []}
						isMulti={true}
					/>

					<Sidebar.Actions>
						<Button variant="secondary" icon={RefreshCcw} type="reset">
							Clear search
						</Button>
						<Button variant="primary" icon={Search} type="submit">
							Search
						</Button>
					</Sidebar.Actions>
				</ValidatedForm>
			</Sidebar>
		</Sidebar.Wrapper>
	);
}
export const ADMIN_AUDIT_LOG_ROUTE: DecentralizedRouteProps = {
	loader,
	element: <AdminAuditLogListPage />,
};
