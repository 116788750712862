import { createColumnHelper } from '@tanstack/react-table';
import ReportOutputPopover from 'iata-cargois-client-portal/src/components/ReportOutputPopover';
import DateFragment from 'ui/components/DateFragment';
import WebToolStatusIndicator from 'ui/components/WebToolStatusIndicator/WebToolStatusIndicator';
import { UsageTrackingDataRow } from '../../api/WebToolUsageTrackingAPI';

const columnHelper = createColumnHelper<UsageTrackingDataRow>();

export const runTypeColumn = columnHelper.accessor('runType', {
	header: 'Report Run',
});
export const reportNameColumn = columnHelper.accessor('reportName', {
	header: 'Report Name',
});

export const statusColumn = columnHelper.accessor('status', {
	header: 'Status',
	cell: ({ row }) => {
		return (
			<WebToolStatusIndicator
				intent={row.original.status.value}
				label={row.original.status.label}
				toolTip={row.original.failureMessage}
			/>
		);
	},
});

export const executedAtColumn = columnHelper.accessor('executedAt', {
	header: 'Run Date/Time (UTC)',
	cell: (info) => (
		<DateFragment includeTime={true} date={info.getValue()} timezone="utc" />
	),
});

export const runTimeColumn = columnHelper.accessor('runTime', {
	header: 'Report Run Time',
});

export const userColumn = columnHelper.accessor('user', {
	header: 'User',
});

export const customerColumn = columnHelper.accessor('customer', {
	header: 'Customer',
});

export const detailsColumn = columnHelper.display({
	header: 'Details',
	cell: (info) => (
		<ReportOutputPopover
			parameters={info.row.original.parameters}
			columns={info.row.original.columns}
		/>
	),
});

export const rowCountColumn = columnHelper.accessor('rowCount', {
	header: 'Report Row results',
});

export const outputFormatColumn = columnHelper.accessor('outputFormat', {
	header: 'Generation Method',
	cell: (info) => {
		if (info.row.original.exportedFormat === null) {
			return info.getValue();
		}

		return (
			<div title={info.row.original.formatChangeMessage}>
				{info.row.original.outputFormat} &gt; {info.row.original.exportedFormat}
			</div>
		);
	},
});
