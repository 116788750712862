import { ReactNode } from 'react';
import { DataSource } from 'utils/types/dataSource';
import FormField, { FormFieldProps } from '../FormField';
import MultiSelect from '../MultiSelect';
import { MultiSelectProps } from '../MultiSelect/MultiSelect';
import Pill from '../Pill';
import useValidation from '../ValidatedForm/useValidation';

type MultiSelectFieldProps<T> = {
	name: string;
	contentSource: DataSource<T, string | ReactNode>;
	options: T[];
	initialValues?: T[];
	pillName: (item: T) => string;
	onCopy?: (value: T[]) => void;
} & FormFieldProps &
	Omit<MultiSelectProps<T>, 'pillComponent' | 'contentSource'>;

const MultiSelectField = <T,>(
	props: Omit<MultiSelectFieldProps<T>, 'children'>
) => {
	const { label } = props;

	const { errorTree } = useValidation(props.name);

	return (
		<FormField
			label={label}
			errors={errorTree?._errors}
			isRequired={props.isRequired}
		>
			<MultiSelect
				{...props}
				placeholder="Select..."
				contentSource={props.contentSource}
				pillComponent={({ value, onRemove }) => (
					<Pill name={props.pillName(value)} onRemove={onRemove} />
				)}
			/>
		</FormField>
	);
};

export default MultiSelectField;
