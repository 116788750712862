import { ENVIRONMENT_SHARED } from 'environment';
import API from 'utils/api/API';

export type CreateAircraftCapacityExportsResponse = {
	id: string;
};

export type GenerateAircraftCapacityUploadLinkResponse = {
	uploadLink: string;
};

class AircraftCapacityAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static createAndRunExport() {
		return AircraftCapacityAPI.post<CreateAircraftCapacityExportsResponse>(
			`/aircraft-capacity/exports`,
			{}
		);
	}

	static async generateUploadLink(filename: string) {
		return AircraftCapacityAPI.post<GenerateAircraftCapacityUploadLinkResponse>(
			`/aircraft-capacity/upload-link/${filename}`
		);
	}

	static async uploadFile(
		apiUrl: string,
		data: BodyInit | null | undefined
	): Promise<Response> {
		const customHeaders: { [key: string]: string } = {};

		customHeaders['Content-Type'] = 'multipart/form-data';

		const requestInit: RequestInit = {
			method: 'PUT',
			body: data,
		};

		requestInit.headers = { ...requestInit.headers, ...customHeaders };

		return await fetch(apiUrl, requestInit);
	}
}

export default AircraftCapacityAPI;
