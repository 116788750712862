import { useRevalidator } from 'react-router-dom';
import { useRef, useState } from 'react';
import { createToast, updateToast } from 'utils/helpers/toast';
import { ToastType } from 'ui/components/Toaster/Toast';
import { downloadFile } from 'utils/helpers/file';
import AdminExportsAPI from '../api/AdminExportsAPI';

export function useExportDownload(): [
	boolean,
	(exportId: string, revalidateOnComplete?: boolean) => void
] {
	const revalidate = useRevalidator();
	const exportPollingTimeout = useRef<number | null>(null);
	const [downloadInProgress, setDownloadInProgress] = useState(false);

	const downloadExport = async (
		exportId: string,
		revalidateOnComplete?: boolean
	) => {
		if (exportPollingTimeout.current) {
			createToast(ToastType.ERROR, 'Download already in progress');
			return;
		}

		// Runs on success or failure
		const cleanUp = function () {
			setDownloadInProgress(false);
			if (revalidateOnComplete) {
				// Must call revalidate outside of the current event loop
				// Otherwise the browser blocks the refresh due to the download happening at the same time
				setTimeout(() => {
					revalidate.revalidate();
				});
			}
		};

		const toastId = createToast(ToastType.LOADING, 'Export started', {
			duration: Infinity,
		});

		const pollExportStatus = async () => {
			const pollingResponse = await AdminExportsAPI.getExportStatus(
				exportId
			).catch(() => null);

			if (pollingResponse === null) {
				exportPollingTimeout.current = null;
				updateToast(toastId, ToastType.ERROR, {
					message: 'Export failed',
					hint: 'We could not check the export status. Please try again.',
				});
				return;
			}

			const { status, downloadUrl } = pollingResponse;

			if (status === 'succeeded') {
				exportPollingTimeout.current = null;
				updateToast(toastId, ToastType.SUCCESS, 'Export succeeded', {
					duration: 5000,
				});

				if (downloadUrl) {
					downloadFile(downloadUrl, downloadUrl);
				}

				cleanUp();
			} else if (status === 'failed') {
				exportPollingTimeout.current = null;

				updateToast(
					toastId,
					ToastType.ERROR,
					{
						message: 'Export failed',
						hint: 'Please try again',
					},
					{
						duration: 4000,
					}
				);
				cleanUp();
			} else {
				if (status === 'created' || status === 'running') {
					updateToast(toastId, ToastType.LOADING, 'Export in progress', {
						duration: Infinity,
					});
				}

				exportPollingTimeout.current = window.setTimeout(
					pollExportStatus,
					1000
				);
			}
		};

		setDownloadInProgress(true);
		pollExportStatus();
	};

	return [downloadInProgress, downloadExport];
}
