import React from 'react';
import {
	LayoutRouteProps,
	LoaderFunctionArgs,
	useLoaderData,
} from 'react-router-dom';
import Flex from 'ui/components/Flex/Flex';
import Pagination from 'ui/components/Pagination';
import RevalidationButton from 'ui/components/RevalidationButton';
import Table from 'ui/components/Table';
import TabActions from 'ui/components/Tabs/TabActions';
import { CddErrorCode } from 'utils/api/CddContributorAPI';
import requireAuthentication from 'utils/helpers/requireAuthentication';
import { LoaderData } from 'utils/types/loaderData';
import SubscriptionAPI from '../../api/SubscriptionAPI';
import * as cddSubmissionColumns from '../../util/columns/cddContributorColumns';

const loader = async ({ request, params }: LoaderFunctionArgs) => {
	await requireAuthentication(request);
	let searchParams = new URL(request.url).searchParams;
	const subscriptionId = params.subscriptionId as string;

	return SubscriptionAPI.getCddContributorList(subscriptionId, searchParams);
};

const SubscriptionCddSubmissions = () => {
	const data = useLoaderData() as LoaderData<typeof loader>;
	const codeDescriptions = React.useMemo(() => {
		const codesMap: Record<string, CddErrorCode> = {};
		for (const errorCode of data.errorCodes) {
			codesMap[errorCode.code] = errorCode;
		}
		return codesMap;
	}, [data.errorCodes]);

	const columns = [
		cddSubmissionColumns.contributorCodeOrPrefixColumn,
		cddSubmissionColumns.externalFileColumn,
		cddSubmissionColumns.receivedAtColumn,
		cddSubmissionColumns.statusColumn(codeDescriptions),
		cddSubmissionColumns.statusContributorColumn,
		cddSubmissionColumns.startedAtColumn,
		cddSubmissionColumns.completedAtColumn,
		cddSubmissionColumns.rowCountColumn,
		cddSubmissionColumns.downloadReportColumn,
	];

	return (
		<>
			<Flex direction="column" gap={16}>
				<Table
					identifierKey="jobId"
					columns={columns}
					data={data.submissions.items}
					emptyText="No submissions found"
				/>

				<TabActions>
					<RevalidationButton size="small">Refresh</RevalidationButton>
				</TabActions>

				{data.submissions.totalCount > data.submissions.pageSize && (
					<Pagination
						baseUrl={new URL(window.location.href)}
						page={data.submissions.page}
						pageParameterName="page"
						pageSize={data.submissions.pageSize}
						itemCount={data.submissions.totalCount}
					/>
				)}
			</Flex>
		</>
	);
};

export const SUBSCRIPTION_DETAILS_CDD_ROUTE: LayoutRouteProps = {
	loader,
	element: <SubscriptionCddSubmissions />,
};
